@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-slate-900;
    @apply text-white;
  }
}

body {
  margin: 0;
  font-family: 'Poppins',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  text-align: center;
  margin: 2rem 0 2rem 0;
}
/* About */
.about__text {
  text-justify: inter-word;
}

/* Form */
.text {
  width: 68%;
}
/* Tokenomics */
/* #tokenomics {
  background-image:
    radial-gradient(
      circle,
      white,
      rgba(30,41,59,0) 32%      
    );
} */
.chart {
  padding: 0.6rem;
  margin-top: 4rem;
  width: 97%;
  height: fit-content;
  border-radius: 20px;
}
.white-innertext {
  color: #000 !important;
}
.white-innertext-label  {
  font-size: 20px;
  font-weight: 700;
}
.white-innertext-value  {
  font-size: 20px;
  font-weight: 700;
}

/* Responsiveness */
@media screen and (max-width: 767px) {
  body {
    font-size: 16px;
  }
  /* About */
  #about {
    padding: 16px 20px 16px 20px ;
    /* font-size: 16px; */
  }
  /* Socials */
  .icon {
    font-size: 0.6em;
    margin: 8px 16px 0 4px !important;
  }
  #socials {
    padding: 6px 28px 6px 0px;
  }
  .social__header {
    font-size: 16px;
  }
  /* Tokenomics */
  .chart {
    width: 95%;
    margin-top: 0;
  }
  /* About */
  .about__text {
  text-justify: inter-word;
  }
  
}
